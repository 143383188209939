var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "admin--clients"
  }, [_c('r-page-header', {
    staticClass: "admin--clientsheader mb-16 mt-8",
    attrs: {
      "label": _vm.$t('admin.clients.title')
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('r-button', {
          attrs: {
            "label": "Add Client"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/admin/clients/add');
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', [_vm.fetchClientsError ? _c('r-error-message', {
    staticClass: "admin--clients--errormessage",
    attrs: {
      "errors": [_vm.fetchClientsError]
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "py-5"
  }, [_c('v-col', {
    staticClass: "ml-auto",
    attrs: {
      "cols": "auto"
    }
  }, [_c('r-search-input', {
    staticClass: "mr-2",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "value": _vm.search
    },
    on: {
      "change": _vm.onChangeSearch
    }
  })], 1)], 1), _vm.search ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "admin--clients--chip"
  }, [_c('span', {
    staticClass: "admin--clients--label"
  }, [_vm._v(" " + _vm._s(_vm.search) + " ")]), _c('v-icon', {
    staticClass: "admin--clients--icon",
    on: {
      "click": _vm.onRemove
    }
  }, [_vm._v(" close ")])], 1)]) : _vm._e(), _c('r-table', {
    staticClass: "clients--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.adminClients,
      "total-rows": _vm.totalRecords,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingClients
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage(value);
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function sortAsc(_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.clientId",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          attrs: {
            "to": "/admin/clients/".concat(item.clientId)
          }
        }, [_vm._v(" " + _vm._s(item.clientId) + " ")])];
      }
    }, {
      key: "item.domainIds",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.domainIds.length) + " ")])];
      }
    }, {
      key: "item.appFeatures",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('r-badge', {
          attrs: {
            "name": item.appFeatures.length.toString(),
            "title": _vm.generateFeatures(item.appFeatures)
          }
        })];
      }
    }, {
      key: "item.suspended",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', [_vm._v(_vm._s(item.suspended ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.lastLogin",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.lastLogin ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastLogin)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.scrapeCadence",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.scrapeCadence ? _c('span', [_vm._v(_vm._s(item.scrapeCadence))]) : _c('span', [_vm._v("Not set")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }