var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', {
    staticClass: "rbadge v-chip--container",
    style: {
      'color': _vm.textColor
    },
    attrs: {
      "color": _vm.color,
      "title": _vm.name,
      "removable": false
    },
    on: {
      "click": _vm.onClick
    }
  }, [!!_vm.prependIcon ? _c('v-icon', {
    staticClass: "mr-1 rbadge--prependicon"
  }, [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]) : _vm._e(), _c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }